import React from "react";
import logo from "./data/Dog's Tail Exp 1.svg";
import marker from "./data/foot-middle.gif";
import copyright from "./data/copyright.svg";
const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div className="footer">
      <div className="logoandlinks">
        <img src={logo} alt="logo" className="logo" />
        <img src={marker} alt="marker" className="marker" />
        <div className="links">
          <a href="./" className="home">
            home.{" "}
          </a>
          <a href="./" className="home">
            works.{" "}
          </a>
          <a href="./" className="home">
            about.{" "}
          </a>
          <a href="./" className="home">
            contact.
          </a>
        </div>
      </div>
      <div className="copyright">
        <img src={copyright} alt="" />
        <p> dogstailexp.com | {currentYear}</p>
      </div>
    </div>
  );
};

export default Footer;

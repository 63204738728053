import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import img1 from "./data/campaign-design.png";
import img2 from "./data/art-direction.png";
import img3 from "./data/fashion-photography.png";
import img4 from "./data/window-display.png";
import img5 from "./data/campaign-design-hover.png";
import img6 from "./data/art-direction-hover.png";
import img7 from "./data/fashion-photography-hover.png";
import img8 from "./data/window-display-hover.png";

export default function ServicesNew() {
  // Responsive settings for the carousel
  const responsiveSettings = {
    0: {
      items: 1,
    },
    600: {
      items: 2,
    },
    1000: {
      items: 3,
    },
  };

  return (
    <div className="container">
      <div className="Services" id="services">
        <h3
          data-aos="fade-up"
          data-aos-anchor-placement="bottom-bottom"
          className="    "
        >
          {" "}
          Services{" "}
        </h3>
      </div>
      {/* // className "owl-theme" is optional */}
      <OwlCarousel
        className="owl-theme"
        loop= {true}
        autoplay= {true}
        margin={30}
        dots=""
        responsive={responsiveSettings} // Add responsive settings here
      >
        <div class="item">
          <div className="flex flex-col">
            <div className="flex items-center justify-center w-full  py-20 sm:py-28 rounded-xl border border-[#222] hover:border-[#A90D06] hover-img-div">
              <img src={img1} alt="" className="serviceIcons default-show" />
              <img src={img5} alt="" className="serviceIcons hover-show" />
            </div>
            <div className="my-5">
              <h3 className="newyork text-lg sm:text-2xl md:text-3xl text-[#ffffff] uppercase">
                campaign design
              </h3>
            </div>
          </div>
        </div>
        <div class="item">
          <div className="flex flex-col">
            <div className="flex items-center justify-center w-full py-20 sm:py-28 rounded-xl border border-[#222] hover:border-[#A90D06] hover-img-div">
              <img src={img2} alt="" className="serviceIcons default-show" />
              <img src={img6} alt="" className="serviceIcons hover-show" />
            </div>
            <div className="my-5">
              <h3 className="newyork text-lg sm:text-2xl md:text-3xl text-[#ffffff] uppercase">
                art direction
              </h3>
            </div>
          </div>
        </div>
        <div class="item">
          <div className="flex flex-col">
            <div className="flex items-center justify-center w-full py-20 sm:py-28 rounded-xl border border-[#222] hover:border-[#A90D06] hover-img-div">
              <img src={img3} alt="" className="serviceIcons default-show" />
              <img src={img7} alt="" className="serviceIcons hover-show" />
            </div>
            <div className="my-5">
              <h3 className="newyork text-lg sm:text-2xl md:text-3xl text-[#ffffff] uppercase">
                fashion photography
              </h3>
            </div>
          </div>
        </div>
        <div class="item">
          <div className="flex flex-col">
            <div className="flex items-center justify-center w-full py-20 sm:py-28 rounded-xl border border-[#222] hover:border-[#A90D06] hover-img-div">
              <img src={img4} alt="" className="serviceIcons2 default-show" />
              <img src={img8} alt="" className="serviceIcons2 hover-show" />
            </div>
            <div className="my-5">
              <h3 className="newyork text-lg sm:text-2xl md:text-3xl text-[#ffffff] uppercase">
                window display
              </h3>
            </div>
          </div>
        </div>
        <div class="item">
          <div className="flex flex-col">
            <div className="flex items-center justify-center w-full py-20 sm:py-28 rounded-xl border border-[#222] hover:border-[#A90D06] hover-img-div">
              <img src={img3} alt="" className="serviceIcons default-show" />
              <img src={img7} alt="" className="serviceIcons hover-show" />
            </div>
            <div className="my-5">
              <h3 className="newyork text-lg sm:text-2xl md:text-3xl text-[#ffffff] uppercase">
                fashion photography
              </h3>
            </div>
          </div>
        </div>
      </OwlCarousel>
    </div>
  );
}

import './App.css';
import Navbar from './Navbar';
import React, { useRef } from "react";
import video1 from "./data/HeroSectionDesktop.mp4";
import videosmall from './data/HeroSectionMobile.mp4'
import ServicesNew from "./ServicesNew";
import About from "./About";
import Contact from "./Contact";
import Footer from "./Footer";
import { HoverLinks } from "./HoverLinks";
import arrow from "./data/arrow-right.svg"
import useAOS from "./useAOS";
import Example from "./Example";
import Typewriter from "./Typewriter";
import logo from "./data/Dog's Tail Exp 1.svg";
import { ReactLenis, useLenis } from 'lenis/react'

function App() {
    useAOS()

    let x
    if (window.innerWidth > 767) {
        x = <video src={video1} alt="video" loop muted autoPlay playsInline className="video1"></video>
    } else {
        x = <video src={videosmall} alt="video" loop muted autoPlay playsInline className="video1"></video>
    }

    const projects = useRef(null)
    const services = useRef(null)
    const about = useRef(null)
    const contact = useRef(null)

    const lenis = useLenis(({ scroll }) => {
        // called every scroll
    })
    const scrollToSection = (elementRef) => {
        window.scrollTo({

            top: elementRef.current.offsetTop,
            behavior: 'smooth'
        })
    }
    return (
        <ReactLenis root>
            <div className="App">
                <div className="flex flex-col justify-around md:mb-16">
                    <div className="mx-2 sm:mx-10 mb-4 md:mx-20">
                        {/*<Navbar/>*/}
                        <nav className="navbar">
                            <img src={logo} alt="Dog's tail logo" />
                            {/*<p>Menu</p>*/}
                            <div className="items">
                                <p onClick={() => scrollToSection(projects)}> Projects </p>
                                <p onClick={() => scrollToSection(services)}> Services </p>
                                <p onClick={() => scrollToSection(about)}> About Us </p>
                                <p onClick={() => scrollToSection(contact)}> Contact </p>
                            </div>
                        </nav>
                    </div>
                    <div className="md:h-[80vh]">
                        {x}
                        <p className="more mt-4 md:my-10"> Scroll for more </p>
                    </div>
                </div>

                <div className="mx-2 sm:mx-10 md:mx-20">
                    {/*<Projects />*/}
                    <div className="projects md:mt-32" data-aos="fade-in" id="projects" ref={projects}>
                        <h2>Projects</h2>
                    </div>
                    <HoverLinks />
                    <a className="seemore" href='./'>
                        See more
                        <img src={arrow} alt="" className="arrow" />
                    </a>
                    <Typewriter text="Crafting narratives that resonate,
                    visuals that inspire,
                    and experiences that linger"/>
                    {/*<Example />*/}
                </div>

                <div className="mx-2 sm:mx-10 md:mx-20">
                    <div ref={services}>
                        <ServicesNew />
                    </div>
                </div>
                <div className="mx-2 sm:mx-10 md:mx-20">
                    <div ref={about}>
                        <About />
                    </div>
                    <div ref={contact}>
                        <Contact />
                    </div>
                    <Footer />
                </div>


            </div>
        </ReactLenis>
    );
}


export default App;
